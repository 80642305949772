import React from "react";

const LinkList = ({ links, onEdit, onDelete }) => {
  return (
    <ul style={styles.list}>
      {links.map((link) => (
        <li key={link.id} style={styles.listItem}>
          <a
            href={link.link}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
          >
            {link.link}
          </a>
          <button onClick={() => onEdit(link.id)} style={styles.button}>
            Edit
          </button>
          <button onClick={() => onDelete(link.id)} style={styles.button}>
            Delete
          </button>
        </li>
      ))}
    </ul>
  );
};

// 스타일 객체
const styles = {
  list: {
    listStyleType: "none",
    padding: "0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5rem",
    borderBottom: "1px solid #ddd",
  },
  link: {
    textDecoration: "none",
    color: "#007bff",
  },
  button: {
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    fontSize: "14px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default LinkList;
