import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LinkForm from "./LinkForm";
import LinkList from "./LinkList";

const LinkManager = ({ fetchLinks, addLink, updateLink, deleteLink }) => {
  const [links, setLinks] = useState([]);
  const [editingLinkId, setEditingLinkId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadLinks = async () => {
      try {
        const response = await fetchLinks();
        setLinks(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/");
        } else {
          console.error("Failed to fetch links:", error);
        }
      }
    };

    loadLinks();
  }, [fetchLinks, navigate]);

  const handleAddLink = (link) => {
    addLink(link).then((response) => {
      setLinks([...links, response.data]);
    });
  };

  const handleEditLink = (id) => {
    setEditingLinkId(id);
  };

  const handleUpdateLink = (link) => {
    updateLink(editingLinkId, link).then((response) => {
      setLinks(links.map((l) => (l.id === editingLinkId ? response.data : l)));
      setEditingLinkId(null);
    });
  };

  const handleDeleteLink = (id) => {
    deleteLink(id).then(() => {
      setLinks(links.filter((link) => link.id !== id));
    });
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Manage Links</h2>
      <LinkForm
        onSubmit={editingLinkId ? handleUpdateLink : handleAddLink}
        initialValue={
          editingLinkId
            ? links.find((link) => link.id === editingLinkId).link
            : ""
        }
      />
      <LinkList
        links={links}
        onEdit={handleEditLink}
        onDelete={handleDeleteLink}
      />
    </div>
  );
};

// 스타일 객체
const styles = {
  container: {
    padding: "2rem",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "80%",
    margin: "0 auto",
  },
  heading: {
    marginBottom: "1rem",
    textAlign: "center",
    color: "#333",
  },
};

export default LinkManager;
