import React, { useState, useEffect } from "react";

const LinkForm = ({ onSubmit, initialValue = "" }) => {
  const [link, setLink] = useState(initialValue);

  useEffect(() => {
    setLink(initialValue);
  }, [initialValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(link);
    setLink("");
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <input
        type="text"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        placeholder="Enter the link"
        required
        style={styles.input}
      />
      <button type="submit" style={styles.button}>
        Submit
      </button>
    </form>
  );
};

// 스타일 객체
const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
  },
  input: {
    marginBottom: "1rem",
    padding: "0.75rem",
    borderRadius: "4px",
    border: "1px solid #ddd",
    fontSize: "16px",
  },
  button: {
    padding: "0.75rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default LinkForm;
