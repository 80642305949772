import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // 환경 변수에서 API URL 가져오기
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // 컴포넌트가 마운트될 때 세션이 유효한지 확인
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get(`${API_URL}/check-session`, {
          withCredentials: true,
        });
        setIsLoggedIn(response.data.loggedIn);
      } catch (error) {
        console.error("Failed to check session status:", error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, [API_URL]);

  const handleLogout = async () => {
    try {
      await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <nav style={styles.nav}>
      {isLoggedIn ? (
        <button onClick={handleLogout} style={styles.button}>
          Logout
        </button>
      ) : (
        <button onClick={() => navigate("/")} style={styles.button}>
          Login
        </button>
      )}
    </nav>
  );
};

// 스타일 객체
const styles = {
  nav: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0.5rem 1rem",
    backgroundColor: "#f8f9fa",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.1)",
  },
  button: {
    padding: "0.5rem 1rem",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
};

export default Navbar;
