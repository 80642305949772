import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import SocialLinks from "./pages/SocialLinks";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/social-links" element={<SocialLinks />} />
      </Routes>
    </Router>
  );
};

export default App;
