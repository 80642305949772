import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// 인증 쿠키를 포함한 GET 요청
export const fetchTwitterLinks = () => {
  return axios.get(`${API_URL}/twitter-links`, { withCredentials: true });
};

// 인증 쿠키를 포함한 POST 요청
export const addTwitterLink = (link) => {
  return axios.post(
    `${API_URL}/twitter-links`,
    { link },
    { withCredentials: true }
  );
};

// 인증 쿠키를 포함한 PUT 요청
export const updateTwitterLink = (id, link) => {
  return axios.put(
    `${API_URL}/twitter-links/${id}`,
    { link },
    { withCredentials: true }
  );
};

// 인증 쿠키를 포함한 DELETE 요청
export const deleteTwitterLink = (id) => {
  return axios.delete(`${API_URL}/twitter-links/${id}`, {
    withCredentials: true,
  });
};

// 인증 쿠키를 포함한 GET 요청
export const fetchYoutubeLinks = () => {
  return axios.get(`${API_URL}/youtube-links`, { withCredentials: true });
};

// 인증 쿠키를 포함한 POST 요청
export const addYoutubeLink = (link) => {
  return axios.post(
    `${API_URL}/youtube-links`,
    { link },
    { withCredentials: true }
  );
};

// 인증 쿠키를 포함한 PUT 요청
export const updateYoutubeLink = (id, link) => {
  return axios.put(
    `${API_URL}/youtube-links/${id}`,
    { link },
    { withCredentials: true }
  );
};

// 인증 쿠키를 포함한 DELETE 요청
export const deleteYoutubeLink = (id) => {
  return axios.delete(`${API_URL}/youtube-links/${id}`, {
    withCredentials: true,
  });
};
