import React from "react";
import LinkManager from "../components/LinkManager";
import {
  fetchTwitterLinks,
  addTwitterLink,
  updateTwitterLink,
  deleteTwitterLink,
  fetchYoutubeLinks,
  addYoutubeLink,
  updateYoutubeLink,
  deleteYoutubeLink,
} from "../api/api.js";
import Navbar from "../components/Navbar.js";
import "./SocialLinks.css";

const SocialLinks = () => {
  return (
    <div id="sociallinks">
      <Navbar />
      <div className="container">
        <div className="sub-container">
          <h1 style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            Twitter Links
            <img src="/images/x-logo-bg.svg" width={40} alt="x-logo" />
          </h1>
          <LinkManager
            fetchLinks={fetchTwitterLinks}
            addLink={addTwitterLink}
            updateLink={updateTwitterLink}
            deleteLink={deleteTwitterLink}
          />
        </div>
        <div className="sub-container">
          <h1 style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            YouTube Links
            <img src="/images/youtube-logo.svg" width={40} alt="youtube-logo" />
          </h1>
          <LinkManager
            fetchLinks={fetchYoutubeLinks}
            addLink={addYoutubeLink}
            updateLink={updateYoutubeLink}
            deleteLink={deleteYoutubeLink}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
